import * as React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import SpecCard from '../../components/SpecCard';
import {
  OdometerIcon,
  TransmissionIcon,
  DrivetrainIcon,
  FuelIcon,
  EngineIcon,
  EnginePowerIcon,
  DoorIcon,
  BodyTypeIcon,
  ColorIcon,
} from '../../../../components/Elements/Icons';
import Chip from '@mui/material/Chip';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useTranslation } from 'react-i18next';

export const VehicleBasicInformation = (props) => {
  const { vehicle } = props;
  const basicInfo = vehicle.basicInformation;
  const { i18n } = useTranslation();
  const kmFormatter = new Intl.NumberFormat('en-CA', {
    style: 'unit',
    unit: 'kilometer',
    unitDisplay: 'short',
  });

  const ChipTags = (props) => {
    const { tag } = props;
    var style = {};
    if (tag.variant === 'filled') {
      style = {
        backgroundColor: tag.style.backGroundColor ?? '',
        color: tag.style.color ?? '',
        fontWeight: '500',
      };
    } else {
      style = {
        borderColor: tag.style.color ?? '',
        color: tag.style.color ?? '',
        fontWeight: '500',
      };
    }

    return (
      <Chip
        variant={tag.variant}
        label={i18n.language == 'fr' ? tag.text?.fr : tag.text?.en}
        sx={style}
      />
    );
  };

  return (
    <Grid container direction='column' sx={{ padding: 2 }}>
      <Grid item>
        <Typography component='span' variant='h4' fontWeight='500'>
          {[basicInfo.year, basicInfo.make, basicInfo.model].join(' ')}
        </Typography>
      </Grid>
      <Grid item sx={{ marginBottom: 2 }}>
        <Typography variant='subtitle1' component='span'>
          {basicInfo.trim} | VIN: <b>{basicInfo.vin}</b>
        </Typography>
      </Grid>
      <Grid item sx={{ marginBottom: 3, alignSelf: 'flex-start' }}>
      {vehicle.carfaxId ? (
        <Button          
          variant="outlined"
          size="medium"
          href={`https://vhr.carfax.ca/?id=${vehicle.carfaxId}`}
          target="_blank"
          sx={{ padding: 1, minWidth: 'auto' }} // Adjust padding and width
        >
          <img
            src="https://shopicar-images.s3.amazonaws.com/0/carfax.jpg"
            alt="CarFax Report"
            style={{ width: 'auto', height: 'auto' }} // Set a reasonable image size
          />
        </Button>
      ) : null}
    </Grid>
      <Grid item sx={{ marginBottom: 2 }}>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
          {vehicle.tags.map((tag) => (
            <ChipTags key={tag.id} tag={tag} />
          ))}
        </Box>
      </Grid>
      <Grid item sx={{ marginBottom: 2 }}>
        <Typography margin='normal' component='div' sx={{ display: 'flex' }}>
          <LocationOnIcon color='secondary' /> {vehicle.city}, QC
        </Typography>
      </Grid>
      <Grid container item spacing={1}>
        <Grid item xs={12} sm={6} md={4}>
          <SpecCard
            label='Odometer'
            value={kmFormatter.format(basicInfo.km)}
            icon={<OdometerIcon color='primary' fontSize='large' />}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <SpecCard
            label='Transmission'
            value={basicInfo.transmission}
            icon={<TransmissionIcon color='primary' fontSize='large' />}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <SpecCard
            label='Drivetrain'
            value={basicInfo.driveTrain}
            icon={<DrivetrainIcon color='primary' fontSize='large' />}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <SpecCard
            label='Fuel'
            value={basicInfo.fuelType}
            icon={<FuelIcon color='primary' fontSize='large' />}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <SpecCard
            label='Engine'
            value={basicInfo.engine}
            icon={<EngineIcon color='primary' fontSize='large' />}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <SpecCard
            label='Power'
            value={basicInfo.enginePower}
            icon={<EnginePowerIcon color='primary' fontSize='large' />}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <SpecCard
            label='Doors'
            value={basicInfo.doors}
            icon={<DoorIcon color='primary' fontSize='large' />}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <SpecCard
            label='Body'
            value={basicInfo.bodyType}
            icon={<BodyTypeIcon color='primary' fontSize='large' />}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <SpecCard
            label='Exterior Color'
            value={basicInfo.color}
            icon={<ColorIcon color='primary' fontSize='large' />}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
