import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import TermsAndConditionsEN from './TermsAndConditionsEN';
import TermsAndConditionsFR from './TermsAndConditionsFR';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { CarDetails } from './CarDetails';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import { postMakeOffer } from '../../../api/postMakeOffer';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import CheckIcon from '@mui/icons-material/Check';
import LoadingButton from '@mui/lab/LoadingButton';
import { getPriceFormatter } from '@/utils/numberFormats';
import { useTranslation } from 'react-i18next';
import { adminApi } from '@/lib/axios';

export default function MakeOfferSteps(props) {
  const { onClose, vehicleData } = props;

  const { t, i18n } = useTranslation();

  const [activeStep, setActiveStep] = React.useState(0);
  const [amountOffer, setAmountOffer] = React.useState(0);
  const [disableFinishButton, setDisableFinishButton] = React.useState(false);
  const [showErrorMessage, setShowErrorMessage] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const sendLogToSlack = ({ title, message, values, type = 2 }) => {
    let msg = message;
  
    if (values && values.length) {
      const formattedMessage = values
        .map((item) => {
          let value = item.value;
          let formatter = "";
  
          switch (item.type) {
            case "code":
              formatter = "`";
              value = JSON.stringify(value);
              break;
            default:
              formatter = "";
          }
  
          return `\r\n*${item.keyname}:* ${formatter}${value}${formatter}`;
        })
        .join("");
  
      msg += formattedMessage;
    }
  
    return adminApi.post("/status/send-message", {
      OpenApiPassword: "rd1JuYMQ7BhEmLeP",
      MessageTypeId: type.toString(),
      Title: title,
      Details: msg,
    });
  };
  
  
  const logErrorToSlack = (error, info) => {
      
    const values = [
      { keyname: "Error", value: error.message, type: "code" },
      { keyname: "Stack", value: error.stack, type: "code" },
      { keyname: "Component Stack", value: info.componentStack, type: "code" },
    ];
  
    sendLogToSlack({
      title: "React Application Error",
      message: "An error occurred in the app.shopitrade React application.",
      values,
      type: 2, 
    }).catch((err) => console.error("Failed to send error to Slack from app.shopitrade:", err));
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setShowErrorMessage(false);
    setDisableFinishButton(false);
    setLoading(false);
  };

  const handleFinish = async (event) => {
    event.preventDefault();
    setDisableFinishButton(true);
    setLoading(true);
    try {
      const makeOfferQuery = await postMakeOffer({
        id: vehicleData.id,
        offer: parseInt(amountOffer),
      });
      setActiveStep(3);
    } catch (error) {
      setShowErrorMessage(true);
      setDisableFinishButton(false);
      setLoading(false);
      logErrorToSlack(error, {
        componentStack: "MakeOfferSteps -> handleFinish (caught in try/catch)",
      });
      console.log(error);
    }
  };

  const handleamountOfferChange = (event) => {
    const value = event.target.value;
    if (/^\d*$/.test(value)) {
      setAmountOffer(event.target.value);
    }
  };

  // const handleReset = () => {
  //   setActiveStep(0);
  // };

  const minimumOfferPourcentage = 1;
  const amountOfferValid = parseInt(amountOffer) >= minimumOfferPourcentage;
  // const formattedminimumOfferPourcentage = new Intl.NumberFormat().format(
  //   minimumOfferPourcentage
  // );

  const errorMessage = showErrorMessage ? (
    <Alert variant='filled' severity='error' color='warning'>
      <AlertTitle>Error</AlertTitle>
      {t('Sorry, an error has occurred, Please try again')}:
    </Alert>
  ) : (
    ''
  );

  const TermAndConditionDescription = () => {
    return (
      <Box variant='filled' severity='error' color='warning'>
        <Typography sx={{ mb: 0, height: '200' }} align='center'>
          {t('Thank you for your offer')}:
        </Typography>
        <Typography sx={{ mb: 2, height: '200' }} align='center'>
          {t(
            'Please be advised that this offer is final and cannot be modified or altered at a later date'
          )}
        </Typography>
        <Paper
          sx={{ p: 2, mb: 5, mr: 'auto', ml: 'auto', height: '200', maxWidth: '300px' }}
          align='center'
          elevation={2}
        >
          <Typography
            variant='span'
            fontSize='20px'
            fontWeight='400'
            sx={{ mb: 0, mr: 1, height: '100' }}
            align='center'
          >
            {t('Your offer')}:
          </Typography>
          <Typography
            variant='span'
            fontSize='23px'
            fontWeight='500'
            color={'primary'}
            sx={{ mb: 0, height: '400' }}
            align='center'
          >
            {getPriceFormatter('USD').format(amountOffer)}
          </Typography>
        </Paper>
      </Box>
    );
  };
  const steps = [
    {
      title: t('Make your offer'),
      // description:
      //   `Please make a minimum offer of ` + formattedminimumOfferPourcentage + `$`,
      content: <CarDetails data={vehicleData} />,
      input: (
        <FormControl fullWidth sx={{ mt: 5, mb: 5 }}>
          <InputLabel htmlFor='outlined-adornment-amount'>
            {/* <b> Minimum Offer {minimumOfferPourcentage} $</b> */}
            {'Your offer'}
          </InputLabel>
          <OutlinedInput
            id='outlined-adornment-amount'
            startAdornment={<InputAdornment position='start'>$</InputAdornment>}
            // label={'Minimum Offer ' + { minimumOfferPourcentage } + '$'}
            label={'Your offer'}
            value={amountOffer}
            type='number'
            onChange={handleamountOfferChange}
          />
        </FormControl>
      ),
      NextButton: (
        <Button disabled={!amountOfferValid} variant='contained' onClick={handleNext}>
          {t('Submit my Offer')} <KeyboardArrowRightIcon />
        </Button>
      ),
      BackButton: (
        <Button onClick={onClose}>
          <KeyboardArrowLeftIcon size='medium' sx={{ mr: 1 }} />
          {t('Go back to Listing')}
        </Button>
      ),
    },
    {
      title: t('Term and conditions'),
      content: <TermAndConditionDescription />,
      errorMessage: { errorMessage },
      NextButton: (
        <LoadingButton
          size='small'
          endIcon={<CheckIcon />}
          loadingPosition='end'
          variant='contained'
          sx={{ mt: 1, mr: 1 }}
          onClick={handleFinish}
          loading={loading}
          disabled={disableFinishButton}
        >
          <span sx={{ m: 0 }}>{t('I accept and agree')}</span>
        </LoadingButton>
      ),
      BackButton: <Button onClick={handleBack}>{t('Back')}</Button>,
      terms: i18n.language == 'fr' ? <TermsAndConditionsFR /> : <TermsAndConditionsEN />,
    },
    {
      title: 'Completed',
      description: '',
      content: null,
      NextButton: (
        <Button variant='contained' onClick={handleNext} sx={{ mt: 1, mr: 1 }}>
          <CheckCircleIcon size='medium' sx={{ mr: 1 }} /> {t('Finish')}
        </Button>
      ),
      BackButton: <Button onClick={handleBack}>{t('Back')}</Button>,
      terms: null,
    },
  ];

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep}>
        {steps.map((step, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={step.title} {...stepProps}>
              <StepLabel {...labelProps}>{step.title}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Box
            sx={{ mt: 5, mb: 2, maxWidth: '800px', mr: 'auto', ml: 'auto' }}
            align='center'
          >
            <Typography variant='h6' component='h6' sx={{ mt: 2, mb: 1 }}>
              {t('Thank you!')}
            </Typography>
            <Typography sx={{ height: '200' }}>
              {t('The Offer was successfully completed')}
            </Typography>
            <Typography sx={{ height: '200' }}>
              {t('you can find all your offers in the Offers section')}
            </Typography>
            <Button variant='contained' onClick={onClose} sx={{ mt: 5 }}>
              <CheckCircleIcon size='medium' sx={{ mr: 1 }} />
              {t('Go back to Listing')}
            </Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Box sx={{ mt: 5, mb: 2, maxWidth: '800px', mr: 'auto', ml: 'auto' }}>
            <Typography variant='h6' component='h6' sx={{ mt: 2, mb: 1 }} align='center'>
              {steps[activeStep].title}
            </Typography>
            {steps[activeStep].description ? (
              <Typography sx={{ mb: 2, height: '200' }} align='center'>
                {steps[activeStep].description}
              </Typography>
            ) : (
              ''
            )}
            <Box sx={{ maxWidth: '300px', mr: 'auto', ml: 'auto' }}>
              {steps[activeStep].input ? steps[activeStep].input : ''}
            </Box>
            {steps[activeStep].content ? steps[activeStep].content : ''}
            {steps[activeStep].terms ? (
              <Paper
                elevation={4}
                sx={{ maxWidth: '100%', height: '200px', overflowY: 'scroll' }}
              >
                <Box sx={{ padding: '5px 20px' }}>{steps[activeStep].terms}</Box>
              </Paper>
            ) : (
              ''
            )}
            <Box sx={{ mr: 'auto', ml: 'auto', mt: 5 }}>{errorMessage}</Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              pt: 2,
              maxWidth: '1000px',
              mr: 'auto',
              ml: 'auto',
            }}
          >
            {steps[activeStep].BackButton ? steps[activeStep].BackButton : ''}
            <Box sx={{ flex: '1 1 auto' }} />
            {steps[activeStep].NextButton}
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
}
