import * as React from 'react';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import CardMedia from '@mui/material/CardMedia';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Link as RouterLink } from 'react-router-dom';
import { getUnitFormatter, getPriceFormatter } from '@/utils/numberFormats';
import { Timer } from './Utils/Timer';
import { ActionButtons } from './Utils/ActionButtons';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

export const VehicleCard = (props) => {
  const { data } = props;    
  const addedToBlockDateParsed = Date.parse(data.dates?.liveDate + ' UTC');
  const deadline = addedToBlockDateParsed + data.duration * 60000;  
  const { i18n } = useTranslation();

  const [isLive, setIsLive] = useState(false);
  const handleCountdownCompletion = () => {
    if (isComingSoon) {
      setIsLive(true);
    }
  };

  const ComingSoonTimer = ({ deadline, onCountdownComplete }) => {
    const [timeLeft, setTimeLeft] = useState(Math.max(0, deadline - new Date()));   
    useEffect(() => {
      if (timeLeft <= 0) {
        return;
      }

      const intervalId = setInterval(() => {
        const newTimeLeft = Math.max(0, deadline - new Date());
        setTimeLeft(newTimeLeft);

        if (newTimeLeft === 0) {
          clearInterval(intervalId);
          onCountdownComplete();
        }
      }, 1000);

      return () => clearInterval(intervalId);
    }, [timeLeft, deadline, onCountdownComplete]);

    if (timeLeft <= 0) {
      return null;
    }

    const seconds = Math.floor((timeLeft / 1000) % 60);
    const minutes = Math.floor((timeLeft / 1000 / 60) % 60);
    const hours = Math.floor((timeLeft / 1000 / 3600) % 24);
    const days = Math.floor(timeLeft / (1000 * 3600 * 24));

    return (
      <span>
        {days > 0 && `${days} days `}
        {hours} hours {minutes} minutes {seconds} seconds
      </span>
    );
  };

  const parseDateAsUTC = (dateStr) => {
    return moment.utc(dateStr, "M/D/YYYY h:mm:ss A").toDate();
  };

  const currentUTCDate = new Date();
  const activeDateUTC = data.dates?.activeDate ? parseDateAsUTC(data.dates.activeDate) : null;
  const isComingSoon = activeDateUTC && activeDateUTC > currentUTCDate;

  const ChipTags = (props) => {
    const { tag } = props;
    var style = {};
    if (tag.variant === 'filled') {
      style = {
        backgroundColor: tag.style.backGroundColor ?? '',
        color: tag.style.color ?? '',
        fontWeight: '500',
      };
    } else {
      style = {
        borderColor: tag.style.color ?? '',
        color: tag.style.color ?? '',
        fontWeight: '500',
      };
    }

    return (
      <Chip
        variant={tag.variant}
        label={i18n.language === 'fr' ? tag.text?.fr : tag.text?.en}
        sx={style}
      />
    );
  };

  const offerText = i18n.language === 'fr' ? 'offre' : 'offer';

  return (
    <Box width='100%' position='relative'>
      <Card
        sx={{
          display: 'flex',
          width: '100%',
          border: '1px solid rgba(0, 0, 0, .125)',
          opacity: 1,
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={3} position="relative">
            <Link
              component={RouterLink}
              to={'vehicle-details/' + data.id}
              underline='none'
              sx={{ pointerEvents: 'cursor' }}
            >
              <Box position="relative">
                <CardMedia
                  component='img'
                  sx={{ width: '100%', maxHeight: '200px' }}
                  image={
                    data.image
                      ? data.image
                      : 'https://s3.amazonaws.com/shopicar-chrome-data-images-main/0.jpg'
                  }
                  alt='Main photo'
                />
                {isComingSoon && (
                    <Box
                    component='span'
                    px={1}
                    sx={{
                      color: '#fff',
                      borderRadius: '4px',
                      backgroundColor: '#52a9dd',
                      position: 'absolute',
                      top: '8px',
                      left: '8px',
                      fontSize: '12px'
                    }}
                  >
                   Live in: <ComingSoonTimer deadline={new Date(activeDateUTC)} onCountdownComplete={handleCountdownCompletion} />
                  </Box>                 
                )}
              </Box>
            </Link>
          </Grid>
          <Grid item xs={12} sm={12} md={9}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} md={8} lg={9}>
                  <Link
                    color='#333'
                    component={RouterLink}
                    to={'vehicle-details/' + data.id}
                    underline='none'
                    sx={{ pointerEvents:'cursor' }}
                  >
                    <Stack spacing={2}>
                      <Box>
                        <Typography component='h5' variant='h5' fontWeight='500'>
                          <Box
                            component='span'
                            px={0.5}
                            sx={{
                              borderRadius: '8px',
                              border: '2px solid',
                              backgroundColor: 'secondary',
                              fontSize: '23px',
                              marginRight: '10px',
                            }}
                          >
                            {data.year}
                          </Box>
                          {[data.make, data.model].join(' ')}
                        </Typography>
                        <Typography
                          variant='subtitle1'
                          color='text.secondary'
                          component='div'
                          fontWeight='500'
                        >
                          {[
                            data.vin,
                            getUnitFormatter('kilometer').format(data.mileage),                            
                            data.transmissionType,
                            data.trim,
                            data.fuelType,                                                     
                          ]
                            .filter((v) => v !== '')
                            .join(' | ')}
                        </Typography>
                      </Box>
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {data.tags.map((tag) => (
                          <ChipTags key={tag.id} tag={tag} />
                        ))}
                      </Box>
                      <Typography
                        margin='normal'
                        component='div'
                        sx={{ display: 'flex' }}
                      >
                        <LocationOnIcon color='secondary' />
                        {data.city}, QC&nbsp;
                        {data.companies && <span> | {data.companies.nameFrom}</span>}
                      </Typography>
                    </Stack>
                  </Link>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Stack spacing={1}>
                    <Box>
                      {data.showPrice && data.price !== 0 && (
                        <Typography
                          component='div'
                          variant='h4'
                          color='primary'
                          fontWeight='500'
                          align='right'
                        >
                          {getPriceFormatter('USD').format(data.price)}
                        </Typography>
                      )}
                      <Typography
                        component='div'
                        variant='h6'
                        fontWeight='500'
                        align='right'
                      >
                        {data.totalOffers} {offerText}{data.totalOffers > 1 ? 's' : ''}
                      </Typography>
                    </Box>
                    <Box>
                      <ActionButtons                       
                        canMakeOffer={data.canMakeOffer}
                        vehicleData={data}
                      />
                    </Box>
                  </Stack>
                </Grid>
              </Grid>
            </CardContent>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};
