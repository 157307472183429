import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { AuthProvider } from '@/contexts/auth';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import CssBaseline from '@mui/material/CssBaseline';
import { queryClient } from '@/lib/react-query';
import { theme } from '@/lib/mui-theme';
import { useTranslation } from 'react-i18next';
import { adminApi } from '@/lib/axios';

const ErrorFallback = () => {
  const { t } = useTranslation();

  return (
    <Box sx={{ p: 20 }} align='center'>
      <h2>{t('Oops, something went wrong')}</h2>
      <Button
        variant='outlined'
        onClick={() => window.location.assign(window.location.origin)}
      >
        {t('Refresh')}
      </Button>
    </Box>
  );
};

const sendLogToSlack = ({ title, message, values, type = 2 }) => {
  let msg = message;

  if (values && values.length) {
    const formattedMessage = values
      .map((item) => {
        let value = item.value;
        let formatter = "";

        switch (item.type) {
          case "code":
            formatter = "`";
            value = JSON.stringify(value);
            break;
          default:
            formatter = "";
        }

        return `\r\n*${item.keyname}:* ${formatter}${value}${formatter}`;
      })
      .join("");

    msg += formattedMessage;
  }

  return adminApi.post("/status/send-message", {
    OpenApiPassword: "rd1JuYMQ7BhEmLeP",
    MessageTypeId: type.toString(),
    Title: title,
    Details: msg,
  });
};


const logErrorToSlack = (error, info) => {
    
  const values = [
    { keyname: "Error", value: error.message, type: "code" },
    { keyname: "Stack", value: error.stack, type: "code" },
    { keyname: "Component Stack", value: info.componentStack, type: "code" },
  ];

  sendLogToSlack({
    title: "React Application Error",
    message: "An error occurred in the app.shopitrade React application.",
    values,
    type: 2, 
  }).catch((err) => console.error("Failed to send error to Slack from app.shopitrade:", err));
};

const theme_1 = createTheme({
  typography: {
    button: {
      // color:'#67b084',
      textTransform: 'none',
    },
  },
  palette: {
    ...theme.palette,
    primary: {
      main: '#52a9dd',
      contrastText: '#fff',
    },
    background: {
      default: 'rgb(244 244 244)',
    },
  },
});

export const AppProvider = ({ children }) => {
  return (
    <ThemeProvider theme={theme_1}>
      <CssBaseline />
      <React.Suspense
        fallback={
          <Box sx={{ p: 20 }} align='center'>
            <CircularProgress size={150} />
          </Box>
        }
      >
        <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onError={logErrorToSlack}
          >
          <HelmetProvider>
            <QueryClientProvider client={queryClient}>
              {process.env.NODE_ENV !== 'test' && <ReactQueryDevtools />}
              <AuthProvider>
                <Router>{children}</Router>
              </AuthProvider>
            </QueryClientProvider>
          </HelmetProvider>
        </ErrorBoundary>
      </React.Suspense>
    </ThemeProvider>
  );
};
