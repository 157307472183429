import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import { Link as RouterLink } from 'react-router-dom';
import { getUnitFormatter, getPriceFormatter } from '@/utils/numberFormats';
import Moment from 'react-moment';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

export const VehicleCard = (props) => {
  const { data } = props;
  const { i18n } = useTranslation();

  const ChipTags = (props) => {
    const { tag } = props;
    var style = {};
    if (tag.variant === 'filled') {
      style = {
        backgroundColor: tag.style.backGroundColor ?? '',
        color: tag.style.color ?? '',
        fontWeight: '500',
      };
    } else {
      style = {
        borderColor: tag.style.color ?? '',
        color: tag.style.color ?? '',
        fontWeight: '500',
      };
    }

    return (
      <Chip
        variant={tag.variant}
        label={i18n.language == 'fr' ? tag.text?.fr : tag.text?.en}
        sx={style}
      />
    );
  };

  const handleButtonClick = (event) => {
    // Stop event propagation to prevent the outer link from being triggered
    event.stopPropagation();
  };

  return (
    <Box width='100%' position='relative'>
      <Card
        sx={{
          display: 'flex',
          width: '100%',
          border: '1px solid rgba(0, 0, 0, .125)',
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={3}>
            <Link
              color='#333'
              component={RouterLink}
              to={'vehicle-details/' + data.id}
              underline='none'
              sx={{ pointerEvents: 'cursor' }}
            >
              <CardMedia
                component='img'
                sx={{ width: '100%', maxHeight: '200px' }}
                image={
                  data.image
                    ? data.image
                    : 'https://s3.amazonaws.com/shopicar-chrome-data-images-main/0.jpg'
                }
                alt='Main photo'
              />
              <Box
                component='span'
                px={1}
                sx={{
                  color: '#fff',
                  borderRadius: '4px',
                  backgroundColor: '#52a9dd',
                  position: 'absolute',
                  top: '8px',
                  left: '8px',
                }}
              >
                {data.status?.name}
              </Box>
            </Link>
          </Grid>
          <Grid item xs={12} sm={12} md={9}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} md={8} lg={9}>
                  <Link
                    color='#333'
                    component={RouterLink}
                    to={'vehicle-details/' + data.id}
                    underline='none'
                    sx={{ pointerEvents: 'cursor' }}
                  >
                    <Stack spacing={2}>
                      <Box>
                        <Typography component='h5' variant='h5' fontWeight='500'>
                          <Box
                            component='span'
                            px={0.5}
                            sx={{
                              borderRadius: '8px',
                              border: '2px solid',
                              backgroundColor: 'secondary',
                              fontSize: '23px',
                              marginRight: '10px',
                            }}
                          >
                            {data.year}
                          </Box>
                          {[data.make, data.model].join(' ')}
                        </Typography>
                        <Typography
                          variant='subtitle1'
                          color='text.secondary'
                          component='div'
                          fontWeight='500'
                        >
                          {[
                            data.vin,
                            getUnitFormatter('kilometer').format(data.mileage),
                            data.transmissionType,
                            data.trim,
                            data.fuelType,
                          ]
                            .filter((v) => v !== '')
                            .join(' | ')}
                        </Typography>
                      </Box>
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {data.tags?.map((tag) => (
                          <ChipTags key={tag.id} tag={tag} />
                        ))}
                      </Box>
                      <Typography
                        margin='normal'
                        component='div'
                        sx={{ display: 'flex' }}
                      >
                        <LocationOnIcon color='secondary' />
                        {data.city}, QC&nbsp;
                        {data.companies && <span> | {data.companies.nameFrom}</span>}
                      </Typography>
                    </Stack>
                  </Link>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      height: '100%',
                    }}
                  >
                    <Box>
                      <Typography
                        component='div'
                        variant='h4'
                        color='primary'
                        fontWeight='500'
                        align='right'
                      >
                        {getPriceFormatter('USD').format(data.price)}
                      </Typography>
                      <Typography
                        component='div'
                        variant='span'
                        fontWeight='400'
                        fontSize='14px'
                        align='right'
                      >
                        <Moment format="LLL">{moment.utc(data.dates?.soldDate ).local()}</Moment>                      
                      </Typography>
                    </Box>
                    <Box sx={{ marginTop: 'auto' }}>
                      {data.carFaxId && (
                        <Button
                          fullWidth
                          variant='outlined'
                          size='large'
                          href={`https://vhr.carfax.ca/?id=${data.carFaxId}`}
                          target='_blank'
                          onClick={handleButtonClick}
                        >
                          <img
                            src='https://shopicar-images.s3.amazonaws.com/0/carfax.jpg'
                            alt='CarFax Report'
                            style={{ width: '100%' }}
                          />
                        </Button>
                      )}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};
